import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFound = () => (
    <div className="container">
      <h1>Doh! 404!</h1>
      <p>These are <em>not</em> the images you are looking for!</p>
    </div>
);

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <NotFound />
  </Layout>
)

export default NotFoundPage
